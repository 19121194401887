var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"complaint"}},[_c('div',{staticClass:"text-end"},[_c('v-text-field',{staticClass:"search-field",attrs:{"clearable":"","label":"Search by Customer name, mobile","prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"complaint-table elevation-0",attrs:{"headers":_vm.headers,"items":_vm.complaints,"server-items-length":_vm.complaintCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"options":_vm.options,"footer-props":{itemsPerPageOptions:_vm.rowsPerPageItems}},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":[function($event){_vm.options=$event},_vm.onUpdateOptions],"click:row":_vm.rowClick,"update:page":function($event){return _vm.$vuetify.goTo(0)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Complaint Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Complaint Available")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Customer.name))]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Customer.Organization.name))]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Customer.mobile))]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"c-email ellipsis",attrs:{"title":item.Customer.email}},[_vm._v(_vm._s(item.Customer.email || '-'))])]}},{key:"item.companyType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Customer.Organization.Type.type || '-'))]}},{key:"item.complaintType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.complaintType || '-'))]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.created_at).format('DD MMM YYYY hh:mm A')))]}}])}),_c('ComplaintDetail',{attrs:{"complaintDetail":_vm.complaintDetail,"dialog":_vm.complaintDetailDialog},on:{"update:dialog":function($event){_vm.complaintDetailDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }