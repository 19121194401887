<template>
<div id="complaint">

        <div class="text-end">
            <v-text-field clearable label="Search by Customer name, mobile"  prepend-inner-icon="mdi-magnify"
                          outlined @input="onSearch" v-model="search" class="search-field"/>
        </div>

        <v-data-table :headers="headers" :items="complaints" :server-items-length="complaintCount"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :options.sync="options"
                      @update:options="onUpdateOptions" @click:row="rowClick" @update:page="$vuetify.goTo(0)"
                      :footer-props="{itemsPerPageOptions:rowsPerPageItems}" class="complaint-table elevation-0">
            <template v-slot:no-data>No Complaint Available</template>
            <template v-slot:no-results>No Complaint Available</template>
            <template v-slot:item.name="{ item }">{{item.Customer.name}}</template>
            <template v-slot:item.companyName="{ item }">{{item.Customer.Organization.name}}</template>
            <template v-slot:item.mobile="{ item }">{{item.Customer.mobile}}</template>
            <template v-slot:item.email="{ item }">
                <span class="c-email ellipsis" :title="item.Customer.email">{{ item.Customer.email || '-' }}</span>
            </template>
            <template v-slot:item.companyType="{ item }">{{item.Customer.Organization.Type.type || '-'}}</template>
            <template v-slot:item.complaintType="{ item }">{{item.complaintType || '-'}}</template>
            <template v-slot:item.created_at="{ item }">{{ moment(item.created_at).format('DD MMM YYYY hh:mm A') }}</template>
        </v-data-table>

    <ComplaintDetail :complaintDetail="complaintDetail" :dialog.sync="complaintDetailDialog"/>

</div>
</template>

<script>
import Constants from "../Constants/Constants";
import moment from "moment";
import {debounce} from "lodash";
import {SUBSCRIBE_COMPLAINT, SUBSCRIBE_COMPLAINT_COUNT} from "../graphql/subscriptions/Complaints";
import ComplaintDetail from "../components/Complaints/ComplaintDetail";
let options= {page: 1, itemsPerPage: 25, offset:0, limit:25};

export default {
    name: "Feedback",
    components: {ComplaintDetail},
    apollo: {
        $subscribe: {
            complaintCount:{
                query: SUBSCRIBE_COMPLAINT_COUNT,
                variables () { return { filter: this.filter }},
                result ({ data }) { this.complaintCount = data.complaintCount.aggregate.count },
            },
            Complaint:{
                query: SUBSCRIBE_COMPLAINT,
                variables () { return { filter: this.filter, offset: this.options.offset, limit: this.options.limit,
                                        orderBy: this.prepareSortObject(this.sortBy, this.sortDesc) }},
                result ({ data }) { this.complaints = data.Complaint },
            },
        }
    },
    data () {
        return {
            Constants: Constants,
            user: JSON.parse(localStorage.user),
            moment: moment,
            headers: [
                {text: 'COMPANY NAME', value: 'companyName'}, {text: 'PERSON', value: 'name'},
                {text: 'MOBILE', value: 'mobile'}, {text: 'EMAIL', value: 'email', width: 240},
                { text: 'TYPE', value: 'companyType' },
                {text: 'COMPLAINT TYPE', value: 'complaintType'}, {text: 'COMPLAINT ON', value: 'created_at', width:180},
            ],
            complaints: [],
            complaintCount: 0,
            options: {...options},
            offset: 0, limit: 25, itemsPerPage: 25, rowsPerPageItems: [25, 50, 100, -1],
            sortBy: ['created_at'], sortDesc: [true],
            search: '',
            filter: '%%',
            complaintDetailDialog: false,
            complaintDetail: null
        }
    },
    methods:{
        prepareSortObject([sortBy], [order]) {
            order = order ? 'desc' : 'asc';
            if (['created_at', 'complaintType'].includes(sortBy)) return {[sortBy]: order};
            if (['name', 'email', 'mobile'].includes(sortBy)) return {Customer: {[sortBy]: order}};
            if (sortBy === 'companyName') return {Customer: {Organization: {name: order}}};
            if (sortBy === 'companyType') return {Customer: {Organization: {Type: {type: order}}}};
            return null
        },
        onUpdateOptions(val){
            if(val.itemsPerPage === -1){                                            //'ALL' Rows per page
                this.options.limit = this.complaintCount                    //assign total count
                this.options.offset = 0
            }else{
                this.options.limit = val.itemsPerPage
                this.options.offset = (val.page -1) * val.itemsPerPage;
            }
        },
        rowClick(row){
            this.complaintDetail = row;
            this.complaintDetailDialog = true
        },
        onSearch: debounce(function(val){
            this.filter = val?('%'+val+'%'):'%%'
            this.options = {...options}             //on search, reset page options
        }, 500),
    },
}
</script>

<style scoped>
#complaint{
    padding: 20px;
}
.search-field{
    display: inline-block;
    width: 300px;
}
.complaint-table{
    margin: -16px 0 0 0;
}
.complaint-table:hover{
    cursor: pointer;
}
.avatar{
    cursor: pointer;
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}
.c-name {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    max-width: 150px;
}
.c-email {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    }
</style>