import gql from 'graphql-tag'
import {IDS} from "../../Constants/IDS";

export const SUBSCRIBE_ALL_USERS = gql`
    subscription ($condition:Users_bool_exp){
        Users(where:$condition,order_by: {updated_at: desc}){
            id
            name
            email
            mobile
            created_at
            userTypeId
            status
            reportsTo
            Type{
                id
                name
            }
            ReportsTo{
                id
                name
            }
        }
    }`
export const SUBSCRIBE_ACTIVE_USER_COUNT = gql`
    subscription {
        ActiveUsers:Users_aggregate(where: {Type: { assigned: {_eq: 2} },status: {_eq: "ACTIVE"},
                                            organizationId: {_eq: "${IDS.organizations.RAJ_WAFERS_MASTER}"}}){
            aggregate{
                count
            }
        }
    }`
export const SUBSCRIBE_INACTIVE_USER_COUNT = gql`
    subscription {
        InActiveUsers:Users_aggregate(where: {Type: { assigned:{_eq: 2} },status: {_eq: "INACTIVE"},
                                              organizationId: {_eq: "${IDS.organizations.RAJ_WAFERS_MASTER}"}}){
            aggregate{
                count
            }
        }
    }`
