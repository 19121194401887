import gql from "graphql-tag";

export const SUBSCRIBE_COMPLAINT_COUNT = gql`
subscription complaintCount($filter: String!){
  complaintCount: Complaint_aggregate (where:{_and: [ {_or: [{Customer: {name: {_ilike: $filter}}}, {Customer: {mobile: {_ilike: $filter}}}, {Customer: {email: {_ilike: $filter}}}, {Customer: {Organization: {name: {_ilike: $filter}}}} ]}]}){
    aggregate{ count }
  }
}`

export const SUBSCRIBE_COMPLAINT = gql`
subscription feedback($offset: Int!, $limit: Int!, $filter: String!, $orderBy: [Complaint_order_by!]){
  Complaint (where:{_and: [ {_or: [{Customer: {name: {_ilike: $filter}}}, {Customer: {mobile: {_ilike: $filter}}}, {Customer: {email: {_ilike: $filter}}}, {Customer: {Organization: {name: {_ilike: $filter}}}} ]}]},
                    offset: $offset, limit: $limit, order_by: $orderBy){
    id
    title
    description  
    complaintType
    Customer { id name mobile email Organization{ id name Type{id type}} }
    Attachments {id image:path}
    created_at
  }
}`