import gql from "graphql-tag";

export const CREATE_UPDATE_PRODUCT = gql`
mutation upsertProduct($product:[Products_insert_input!]!, $updateColumns: [Products_update_column!]!) {
  insert_Products(objects: $product, on_conflict: {constraint: Products_pkey, update_columns: $updateColumns}){
    returning{id}
  }  
}`

export const CREATE_UPDATE_PRODUCT_PRICES = gql`
mutation upsertProductPrices($productPrices:[Prices_insert_input!]!, $updateColumns: [Prices_update_column!]!) {
  insert_Prices(objects: $productPrices, on_conflict: {constraint: Prices_pkey, update_columns: $updateColumns}){
    returning{id}
  }  
}`

export const CREATE_UPDATE_PRODUCT_NAME_AND_WEIGHT = gql`
mutation updateProduct($productId: uuid!, $displayName: String, $weight: Int) {
  update_Products(where: { id: { _eq: $productId } }, _set: { 
    displayName: $displayName, 
    weight: $weight 
  }) {
    returning {
      id
      displayName
      weight
    }
  }
}`
