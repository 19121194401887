<template>
  <div id="Users">
    <v-container fluid>
      <v-card class="elevation-0 mb-5" height="50">
        <v-card-title>
          <v-row>
            <div @click="filterUsers('all')" class="count-widget-all px-3  py-2"
                 :class="selectedFilter==='all'?'count-widget-all-active':''">
              <p class="text-body-2 mb-0">All Users</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ InActiveUsers + ActiveUsers }}</h4>
            </div>
            <div @click="filterUsers('active')" class="count-widget-assign px-3  py-2 ml-2"
                 :class="selectedFilter==='active'?'count-widget-assign-active':''">
              <p class="text-body-2 mb-0">Active Users</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ ActiveUsers }}</h4>
            </div>
            <div @click="filterUsers('inactive')" class="count-widget px-3 py-2  ml-2"
                 :class="selectedFilter==='inactive'?'count-widget-active':''">
              <p class="text-body-2 mb-0">Inactive Users</p>
              <h4 class="text-subtitle-1 font-weight-bold">{{ InActiveUsers }}</h4>
            </div>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="3">
              <v-text-field clearable dense label="Search Users" outlined v-model="search"></v-text-field>
            </v-col>
            <v-col class="pa-0 ml-2 mr-1" cols="auto">
              <v-btn v-if="permissions.permissionMeta.create" @click="startCreateUser" class="primary elevation-0"
                     depressed>
                ADD USER
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col class="px-2 mt-3" cols="12">
          <v-data-table fixed-header :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page="25"
                        :loading="loading"
                        :headers="headers" :items="users" :search="search" height="70vh" class="elevation-0">
            <template v-slot:no-data>No Users Available</template>
            <template v-slot:no-results>No Users Available</template>
            <template v-slot:item.name="{ item }">
              {{ item.name ? item.name : '-' }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.Type ? item.Type.name : '-' }}
            </template>
            <template v-slot:item.mobile="{ item }">
              {{ item.mobile ? item.mobile : '-' }}
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email ? item.email : '-' }}
            </template>
            <template v-slot:item.reportsTo="{ item }">
              {{ item.ReportsTo ? checkVal(item.ReportsTo.name) : '-' }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ dateTimeFilter(item.created_at) }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ item.status ? item.status : '-' }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center">
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[0],item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click.stop="onClickAction(actionArr[1],item)" v-if="permissions.permissionMeta.delete">
                  <v-icon :color="item.status==='ACTIVE'?'blue':'red'" size="32">
                    {{ item.status === 'ACTIVE' ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <CreateUpdateUser :user="selectedUser" :userDialog="userDialog" @cancel="userDialog=false" @saveUser="saveUser"
                      v-if="userDialog" @error="showError"></CreateUpdateUser>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @confirm="confirmAction" v-if="confirmationDialog"
                        @close="confirmationDialog=false;confirmMsg = 'Confirm! '"></ConfirmationDialog>
    <Snackbar :toaster="toaster"></Snackbar>
  </div>
</template>

<script>
import Snackbar from '@/components/Common/Snackbar'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import CreateUpdateUser from '../components/Users/CreateUpdateUser'
import {
  SUBSCRIBE_ACTIVE_USER_COUNT,
  SUBSCRIBE_ALL_USERS,
  SUBSCRIBE_INACTIVE_USER_COUNT,
} from '../graphql/subscriptions/Users'
import { SOFT_DELETE_USER } from '@/graphql/mutations/Users'
import {IDS} from "../Constants/IDS";
let condition = { organizationId :{_eq: IDS.organizations.RAJ_WAFERS_MASTER}, Type: {assigned: {_eq: 2}}};
export default {
  name: 'Users',
  components: { CreateUpdateUser, ConfirmationDialog, Snackbar },
  data () {
    return {
      users: [], search: '', loading: true, confirmationDialog: false, userDialog: false,
      toaster: {enable: false, color: 'green', message: ''}, permissions: {},
      ActiveUsers: 0, InActiveUsers: 0, selectedFilter: 'all',
      condition: {...condition},
      confirmMsg: 'Confirm! ', selectedAction: {}, selectedUser: {},
      headers: [
        {text: 'Name', value: 'name', width: '110'}, {text: 'Type', value: 'type', width: '150'},
        {text: 'Mobile', value: 'mobile', width: '120'}, {text: 'Email', value: 'email', width: '120'},
        {text: 'Reports To', value: 'reportsTo', width: '120'},
        {text: 'Created On', value: 'created_at', width: '200'},
        {text: 'Actions', value: 'actions', width: '90'},
      ],
      actionArr: [{id: '1', title: 'Edit'}, {id: '2', title: 'Delete'}],
    }
  },
  apollo: {
    $subscribe: {
      Users: {
        query: SUBSCRIBE_ALL_USERS, fetchPolicy: 'network-only',
        variables () { return { condition: this.condition } },
        result ({ data, loading }) {
          if (!loading) {
            this.users = data.Users
            this.loading = false
          }
        },
      }, ActiveUsers: {
        query: SUBSCRIBE_ACTIVE_USER_COUNT, fetchPolicy: 'network-only',
        result ({ data, loading }) {
          if (!loading) {
            this.ActiveUsers = data.ActiveUsers.aggregate.count
          }
        },
      }, InActiveUsers: {
        query: SUBSCRIBE_INACTIVE_USER_COUNT, fetchPolicy: 'network-only',
        result ({ data, loading }) {
          if (!loading) {
            this.InActiveUsers = data.InActiveUsers.aggregate.count
          }
        },
      },
    },
  },
  beforeMount () {
    this.permissions = this.$store.getters.permissionByScreen('Users')
  },
  methods: {
    onClickAction (selectedAction, selectedUser) {
      this.selectedUser = selectedUser
      this.selectedAction = this.actionArr.filter(action => selectedAction.id === action.id)[0]
      if (this.selectedAction.id === '1')
        this.confirmAction()
      else if (this.selectedAction.id === '2')
        this.confirmMsg = this.confirmMsg.concat('this will update user status, are you sure?')
      this.confirmationDialog = this.selectedAction.id !== '1'
    },
    confirmAction () {
      this.confirmationDialog = false
      this.confirmMsg = 'Confirm! '
      if (this.selectedAction.id === '1')
        this.updateUser()
      else if (this.selectedAction.id === '2')
        this.deleteUser()
    },
    startCreateUser () {
      this.selectedUser = null
      this.userDialog = true
    },
    updateUser () { this.userDialog = true },
    async deleteUser () {
      let response = await this.$apollo.mutate({
        mutation: SOFT_DELETE_USER,
        variables: {
          id: this.selectedUser.id, status: this.selectedUser.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
        },
      })
      if (response.data)
        this.toaster = { enable: true, color: 'green', message: 'User successfully updated' }
    },
    saveUser () {
      this.toaster = { enable: true, color: 'green', message: `User saved successfully` }
      this.userDialog = false
    },
    filterUsers (filterType) {
      this.loading = true
      this.selectedFilter = filterType
      if (this.selectedFilter === 'active')
        this.condition = {...condition, status: { _eq: 'ACTIVE' } };
      else if (this.selectedFilter === 'inactive')
        this.condition = {...condition, status: { _eq: 'INACTIVE' } };
      else this.condition = { ...condition }
    },
    showError (message) { this.toaster = { enable: true, color: 'red', message: message } },
  },
}
</script>
