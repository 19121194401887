<template>
  <div id="CreateUpdateUser">
    <v-dialog max-width="600" scrollable persistent v-model="userDialog">
      <v-card>
        <v-card-title >{{ user ? 'Update' : 'Create' }} User</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <v-form ref="form">
            <v-row>
              <v-col class="py-1 mt-5" cols="12">
                <v-text-field :rules="[rules.nameRequired]" dense label="Name*" outlined
                              ref="name" v-model="userData.name"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="12">
                <v-text-field :rules="[rules.mobileRequired,rules.mobileLength]" dense label="Mobile*"
                              outlined ref="mobile" v-mask="'##########'" v-model="userData.mobile"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="12">
                <v-text-field :rules="[rules.emailValid]" dense label="Email"
                              outlined ref="email" v-model="userData.email"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="12">
                <v-select :items="userTypes" :rules="[rules.userTypeRequired]" dense item-text="name" ref="userType"
                          item-value="id" label="User Type*" outlined v-model="userData.userTypeId" @change="onChangeUserType"></v-select>
              </v-col>
              <v-col class="py-1" cols="12">
                <v-autocomplete :items="reportsToUsers" dense item-text="name" item-value="id" label="Reports To" outlined
                                ref="reportsTo" v-model="userData.reportsTo">
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{data.item.Type.name}}</v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn depressed @click="validate" class="mr-2 primary elevation-0">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GET_USER_BY_MOBILE, GET_USERS_BY_ORGANIZATION } from '../../graphql/queries/Users'
import { CREATE_UPDATE_USER } from '@/graphql/mutations/Users'
import { GET_USER_TYPES } from '../../graphql/queries/UserTypes'
import {IDS} from "../../Constants/IDS";

export default {
  name: 'CreateUpdateUser',
  props: {
    user: { type: [Object], default: null },
    userDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      userData: { name: '', userTypeId: '', mobile: '', email: '', reportsTo: null },
      userTypes: [], users: [],
      loggedInUser: JSON.parse(localStorage.user),
      rules: {
        nameRequired: value => !!value || 'Name Required.',
        mobileRequired: value => !!value || 'Mobile Required.',
        mobileLength: v => v ? v.length === 10 : 'Must be 10 characters',
        emailValid: v => !v ||
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v) || 'E-mail must be valid',
        userTypeRequired: value => !!value || 'User Type Required.',
      },
    }
  },
  computed:{
    reportsToUsers: function(){
      if(this.userData.userTypeId === IDS.userTypes.PilotSalesman){
        return this.users.filter(u => [IDS.userTypes.SalesOfficer, IDS.userTypes.AreaSalesManager].includes(u.userTypeId ))
      }else if(this.userData.userTypeId === IDS.userTypes.SalesOfficer){
        return this.users.filter(u => [IDS.userTypes.AreaSalesManager].includes(u.userTypeId ))
      }else if(this.userData.userTypeId === IDS.userTypes.AreaSalesManager){
        return this.users.filter(u => [IDS.userTypes.SalesMarketingManager].includes(u.userTypeId ))
      }else if(this.userData.userTypeId === IDS.userTypes.SalesMarketingManager){
        return this.users.filter(u => [IDS.userTypes.Director].includes(u.userTypeId ))
      }else if(this.userData.userTypeId === IDS.userTypes.Admin){
        return this.users.filter(u => [IDS.userTypes.Director].includes(u.userTypeId ))
      }else if(this.userData.userTypeId === IDS.userTypes.Staff){
        return this.users.filter(u => [IDS.userTypes.Admin].includes(u.userTypeId ))
      }else return []
    }
  },
  apollo: {
    Users: {
      query: GET_USERS_BY_ORGANIZATION, fetchPolicy: 'network-only',
      variables () {return { organizationId: IDS.organizations.RAJ_WAFERS_MASTER }},
      result ({ data, loading }) { if (!loading) { this.users = data.Users } },
    },
    UserTypes: {
      query: GET_USER_TYPES, fetchPolicy: 'network-only',
      result ({ data, loading }) { if (!loading) { this.userTypes = data.UserTypes } },
    },
  },
  mounted () {
    if (this.user)
      this.userData = JSON.parse(JSON.stringify(this.user))
    else
      this.userData['id'] = this.generateUUID()
  },
  methods: {
    onChangeUserType(){
      this.userData.reportsTo = null;
    },
    validate () { if (this.$refs.form.validate()) this.checkMobileDuplication() },
    async checkMobileDuplication () {
      let response = await this.$apollo.query({
        query: GET_USER_BY_MOBILE, fetchPolicy: 'network-only',
        variables: { id: this.userData.id, mobile: this.userData.mobile },
      })
      if (response.data) {
        if (response.data.Users.length)
          this.$emit('error', 'Mobile Already Exists')
        else
          await this.saveUser()
      }
    },
    async saveUser () {
      let userObj = {
        id: this.userData.id,
        name: this.userData.name,
        email: this.userData.email,
        mobile: this.userData.mobile,
        userTypeId: this.userData.userTypeId,
        reportsTo: this.userData.reportsTo,
        organizationId: IDS.organizations.RAJ_WAFERS_MASTER,         /* this.loggedInUser.organizationId */
      }
      let user_on_conflict_rule = {
        constraint: 'Users_pkey',
        update_columns: ['name', 'email', 'mobile', 'organizationId', 'userTypeId', 'reportsTo', 'status', 'updatedBy'],
      }
      let response = await this.$apollo.mutate({
        mutation: CREATE_UPDATE_USER,
        variables: { users: userObj, user_on_conflict_rule: user_on_conflict_rule },
      })
      if (response.data)
        this.$emit('saveUser')
    },
    cancel () { this.$emit('cancel') },
    generateUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
  },
}
</script>
