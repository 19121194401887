<template>
<div>

    <v-dialog v-model="dialog" persistent max-width="600" scrollable>
        <v-card>
            <v-card-title class="text-h5 pl-6 font-weight-regular">Scheme Detail
                <v-spacer/><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title><v-divider/>
            <v-card-text class="mt-4">
                <v-form ref="form">
                    <v-row>
                        <v-col cols="12">
                            <label class="text-uppercase grey--text font-weight-bold">Scheme</label>
                            <v-textarea outlined v-model="form.scheme" class="mt-3" counter validate-on-blur placeholder="Enter text..."
                                        :rules="[v => !v || v.length>=3 || 'Minimum 3 chars', v => v.length<=150 || 'Maximum 150 chars']"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <v-btn class="text-capitalize elevation-0 mr-2" @click="closeDialog">Cancel</v-btn>
                <v-btn class="primary px-3 text-capitalize" :loading="formLoading" dark @click="handleSubmit">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <Snackbar :toaster="toaster"/>
</div>
</template>

<script>
import {GET_PRODUCT_SCHEME} from "../../graphql/queries/Products";
import updateRecord from '../../mixins/updateRecord';
import Snackbar from "../Common/Snackbar";
let form = { scheme: ''};
export default {
    name: "SchemeForm",
    components: {Snackbar},
    props: ['dialog', 'productId'],
    mixins: [updateRecord],
    apollo: {
        Products: {
            query: GET_PRODUCT_SCHEME,
            fetchPolicy: 'network-only',
            skip () {return !this.productId},
            variables(){ return{ id: this.productId } },
            result ({data}) { this.form.scheme = data.Products[0].scheme },
        },
    },
    data(){
        return{
            form: {...form},
            formLoading: false,
            toaster: {enable: false}
        }
    },
    methods:{
        handleSubmit(){
            if(this.$refs.form.validate())
                this.saveProductScheme()
        },
        async saveProductScheme(){
            this.formLoading = true;
            this.form.scheme = this.form.scheme || null;
            await this.updateRecord(this.productId, this.form, 'Products');
            this.formLoading = false;
            this.toaster = {enable:true, color:'green', message: `Scheme saved Successfully`};
            this.closeDialog();
        },
        closeDialog(){
            this.$refs.form.resetValidation();
            this.form = {...form};
            this.$emit('update:dialog', false);
            this.$emit('update:productId', null);
        }
    }
}
</script>

<style scoped>

</style>