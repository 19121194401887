<template>
<div>

    <v-dialog v-model="dialog" persistent width="1000">
        <v-card>
            <v-card-title class="text-subtitle-1 font-weight-regular">Complaint Detail
                <v-spacer/><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="complaintDetail">
                <v-row>
                    <v-col cols="6">
                        <div class="complaint-card pa-5">
                            <h4 class="text-h6 black--text mb-5">{{complaintDetail.Customer.Organization.name}}</h4>

                            <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-warehouse</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.Organization.Type.type}}</span>
                            </div>
                            <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-account</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.name}}</span>
                            </div>
                            <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-phone-outline</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.mobile}}</span>
                            </div>
                            <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-at</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.email}}</span>
                            </div>
                           <!-- <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-map-marker-outline</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.city || '-'}}</span>
                            </div>
                            <div class="d-flex align-center mb-2">
                                <v-icon size="16">mdi-briefcase-account-outline</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.Customer.SalesExecutive?feedbackDetail.Customer.SalesExecutive.name:'-'}}</span>
                            </div>  -->
                            <div class="d-flex align-start mb-2">
                                <v-icon size="16">mdi-calendar-month-outline</v-icon>
                                <span class="text-body-2 ml-3">{{moment(complaintDetail.created_at).format('DD MMM YYYY hh:mm A')}}</span>
                            </div>
                            <div class="d-flex align-start mb-2">
                                <v-icon size="16" title="Type">mdi-format-list-bulleted</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.complaintType}}</span>
                            </div>
                            <div class="d-flex align-start mb-2">
                                <v-icon size="16" title="Title">mdi-format-title</v-icon>
                                <span class="text-body-2 ml-3">{{complaintDetail.title}}</span>
                            </div>
                            <div class="d-flex align-start">
                                <v-icon size="16">mdi-format-quote-open-outline</v-icon>
                                <div class="text-body-2 ml-3" v-html="complaintDetail.description"></div>
                            </div>
                        </div>
                    </v-col>



                    <v-col cols="6" style="max-height: 600px; overflow-y: auto; ">
                        <div class="row">
                            <div class="col-6" v-for="(a, index) in complaintDetail.Attachments" :key="index" @click.stop="openGallery(complaintDetail.Attachments, index)" title="View Image">
                                <v-img :src="imgWpx(a.image)" class="img-inner-container"/>
                            </div>
                            <div v-if="!complaintDetail.Attachments.length" style="color: #202020">No Attachments added.</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <gallery id="gallery-complaint-detail" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
</div>
</template>

<script>
import moment from 'moment';
import gallery from '../../mixins/gallery';
export default {
    name: "ComplaintDetail",
    props: ['dialog', 'complaintDetail'],
    mixins: [gallery],
    data(){
        return{
            moment: moment
        }
    },
    methods:{
        closeDialog(){
            this.$emit('update:dialog', false)
        }
    }
}
</script>

<style scoped>
.content-label{
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 2px 0;
}
.content-text{
    padding: 0 0 0 2px;
    color: #202020;
}
.img-inner-container{
    height: 100px;
    /*width: 150px;*/
    position: relative;
    border-radius: 4px;
    border: solid grey 1px;
    cursor: pointer;
}

.complaint-card{
    background: #F5F5F5;
    border-radius: 10px;
}
</style>