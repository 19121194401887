<template>
  <div id="Products">
    <v-container fluid class="fill-height">
      <v-row class="mb-2">
        <v-col cols="9">
          <h2 class="pl-3 text-h6 font-weight-regular">
            Products ({{ products.length }})
          </h2>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            placeholder="Search Product ..."
            @input="onSearch"
            v-model="search"
            clearable
            hide-details
          />
        </v-col>
      </v-row>
      <v-card v-if="isUserHasPermission" outlined width="100%" class="product-card" :loading="loading">
        <draggable
          @end="
            drag = false;
            updateSequence();
          "
          @start="drag = true"
          group="people"
          v-model="products"
          class="py-0"
          v-bind="dragOptions"
          :disabled="!!search"
        >
          <template v-for="(product, index) in products">
            <v-row :key="index" v-if="!product.isHidden" class="p-row">
              <v-col cols="12" class="py-0">
                <v-card elevation="0" class="py-0">
                  <v-card-text class="py-0">
                    <v-row row wrap>
                      <v-col cols="2">
                        <v-icon class="drag-icon">mdi-drag</v-icon>
                        <div
                          class="raj-wafer-images text-center"
                          v-if="product.image"
                          @click="openGallery([{ image: product.image }])"
                        >
                          <img
                            width="50"
                            class="d-block mx-auto"
                            alt="imges not found"
                            :src="imgWpx(product.image, 200)"
                          />
                          <v-chip
                            label
                            text-color="white"
                            class="mt-3"
                            x-small
                            v-if="product.packageType"
                            :style="
                              `background-color: ${
                                packageColor[product.packageType]
                              }`
                            "
                          >
                            {{ product.packageType.toUpperCase() }}
                          </v-chip>
                        </div>
                        <div
                          style="position: relative;top: calc(50% - 13px);text-align: -webkit-center;"
                          v-else
                        >
                          <v-icon>image</v-icon>
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <v-row>
                          <v-col class="pa-0 px-1" cols="5">
                            <div>

                              <div class="d-flex align-items-center">
                                <h6
                                  class="text-subtitle-1 font-weight-bold"
                                  :style="{
                                    color: product.colorCode
                                      ? product.colorCode
                                      : '#3172d0',
                                  }">
                                  {{ product.displayName }}
                                </h6>
                                <div v-if="isUserHasPermission" class="d-flex align-items-center ml-3">
                                  <v-btn
                                    icon
                                    @click="openUpdateProductName(product)"
                                    color="grey"
                                    x-small
                                    class="mt-1"
                                    ><v-icon>mdi-pencil</v-icon></v-btn>
                                </div>
                              </div>

                              <div class="d-flex align-items-center">
                                <p class="text-caption font-italic mb-0">
                                  {{ product.noOfPieceInBox }} Pieces in
                                  {{ product.containerType }}
                                </p>
                                <div v-if="isUserHasPermission" class="d-flex align-items-center ml-3">
                                  <v-btn
                                    icon
                                    @click="openUpdateProductPrice(product)"
                                    color="grey"
                                    x-small
                                    class="mt-0"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  >
                                </div>
                              </div>

                            </div>
                          </v-col>

                          <v-col cols="5">
                            <v-row>
                              <v-col class="pa-0" cols="3">
                                <div class="text-caption">Distributor</div>
                                <div class="text-caption">Dealer</div>
                                <div class="text-caption">Retailer</div>
                              </v-col>
                              <v-col class="pa-0" cols="3">
                                <div class="text-caption black--text">
                                  ₹ {{ product.DistributorPiecePrice }}/pcs
                                </div>
                                <div class="text-caption black--text">
                                  ₹ {{ product.SubDistributorPiecePrice }}/pcs
                                </div>
                                <div class="text-caption black--text">
                                  ₹ {{ product.RetailerPiecePrice }}/pcs
                                </div>
                              </v-col>
                              <v-col class="pa-0" cols="3">
                                <div class="text-caption black--text">
                                  ₹ {{ product.DistributorBoxPrice }}/{{
                                    product.containerType
                                  }}
                                </div>
                                <div class="text-caption black--text">
                                  ₹ {{ product.SubDistributorBoxPrice }}/{{
                                    product.containerType
                                  }}
                                </div>
                                <div class="text-caption black--text">
                                  ₹ {{ product.RetailerBoxPrice }}/{{
                                    product.containerType
                                  }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col v-if="isUserHasPermission" cols="1">
                            <v-switch
                              inset
                              v-model="product.isEnable"
                              @change="onUpdateStatus(product)"
                              color="success"
                            />
                          </v-col>
                          <v-col v-if="isUserHasPermission" cols="1">
                            <v-btn
                              @click="openSchemeForm(product)"
                              small
                              rounded
                              outlined
                              text
                              :class="
                                product.scheme
                                  ? 'success white--text mt-4'
                                  : 'mt-4'
                              "
                              >Scheme</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class="py-0" cols="12"><v-divider /></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </draggable>
      </v-card>
      <v-card v-if="!isUserHasPermission" outlined width="100%" class="product-card" :loading="loading">
        <template v-for="(product, index) in products">
        <v-row :key="index" v-if="!product.isHidden" class="p-row">
            <v-col cols="12" class="py-0">
            <v-card elevation="0" class="py-0">
                <v-card-text class="py-0">
                <v-row row wrap>
                    <v-col cols="2">
                    <v-icon class="drag-icon">mdi-drag</v-icon>
                    <div
                        class="raj-wafer-images text-center"
                        v-if="product.image"
                        @click="openGallery([{ image: product.image }])"
                    >
                        <img
                        width="50"
                        class="d-block mx-auto"
                        alt="imges not found"
                        :src="imgWpx(product.image, 200)"
                        />
                        <v-chip
                        label
                        text-color="white"
                        class="mt-3"
                        x-small
                        v-if="product.packageType"
                        :style="
                            `background-color: ${
                            packageColor[product.packageType]
                            }`
                        "
                        >
                        {{ product.packageType.toUpperCase() }}
                        </v-chip>
                    </div>
                    <div
                        style="position: relative;top: calc(50% - 13px);text-align: -webkit-center;"
                        v-else
                    >
                        <v-icon>image</v-icon>
                    </div>
                    </v-col>
                    <v-col cols="10">
                    <v-row>
                        <v-col class="pa-0 px-1" cols="5">
                        <div>

                            <div class="d-flex align-items-center">
                            <h6
                                class="text-subtitle-1 font-weight-bold"
                                :style="{
                                color: product.colorCode
                                    ? product.colorCode
                                    : '#3172d0',
                                }">
                                {{ product.displayName }}
                            </h6>
                            <div v-if="isUserHasPermission" class="d-flex align-items-center ml-3">
                                <v-btn
                                icon
                                @click="openUpdateProductName(product)"
                                color="grey"
                                x-small
                                class="mt-1"
                                ><v-icon>mdi-pencil</v-icon></v-btn>
                            </div>
                            </div>

                            <div class="d-flex align-items-center">
                            <p class="text-caption font-italic mb-0">
                                {{ product.noOfPieceInBox }} Pieces in
                                {{ product.containerType }}
                            </p>
                            <div v-if="isUserHasPermission" class="d-flex align-items-center ml-3">
                                <v-btn
                                icon
                                @click="openUpdateProductPrice(product)"
                                color="grey"
                                x-small
                                class="mt-0"
                                ><v-icon>mdi-pencil</v-icon></v-btn
                                >
                            </div>
                            </div>

                        </div>
                        </v-col>

                        <v-col cols="5">
                        <v-row>
                            <v-col class="pa-0" cols="3">
                            <div class="text-caption">Distributor</div>
                            <div class="text-caption">Dealer</div>
                            <div class="text-caption">Retailer</div>
                            </v-col>
                            <v-col class="pa-0" cols="3">
                            <div class="text-caption black--text">
                                ₹ {{ product.DistributorPiecePrice }}/pcs
                            </div>
                            <div class="text-caption black--text">
                                ₹ {{ product.SubDistributorPiecePrice }}/pcs
                            </div>
                            <div class="text-caption black--text">
                                ₹ {{ product.RetailerPiecePrice }}/pcs
                            </div>
                            </v-col>
                            <v-col class="pa-0" cols="3">
                            <div class="text-caption black--text">
                                ₹ {{ product.DistributorBoxPrice }}/{{
                                product.containerType
                                }}
                            </div>
                            <div class="text-caption black--text">
                                ₹ {{ product.SubDistributorBoxPrice }}/{{
                                product.containerType
                                }}
                            </div>
                            <div class="text-caption black--text">
                                ₹ {{ product.RetailerBoxPrice }}/{{
                                product.containerType
                                }}
                            </div>
                            </v-col>
                        </v-row>
                        </v-col>
                        <v-col v-if="isUserHasPermission" cols="1">
                        <v-switch
                            inset
                            v-model="product.isEnable"
                            @change="onUpdateStatus(product)"
                            color="success"
                        />
                        </v-col>
                        <v-col v-if="isUserHasPermission" cols="1">
                        <v-btn
                            @click="openSchemeForm(product)"
                            small
                            rounded
                            outlined
                            text
                            :class="
                            product.scheme
                                ? 'success white--text mt-4'
                                : 'mt-4'
                            "
                            >Scheme</v-btn
                        >
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12"><v-divider /></v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
        </template>
      </v-card>
    </v-container>

    <SchemeForm :dialog.sync="formDialog" :productId.sync="productId" />
    <UpdateProductPrice
      :dialog.sync="updateProductPriceDialog"
      :productId.sync="productId"
    />
    <UpdateProductName
      :dialog.sync="updateProductNameDialog"
      :productId.sync="productId"
    />
    <ConfirmationDialog
      :confirmationMessage="confirm.message"
      @confirm="confirm.method"
      @close="confirm.closeMethod"
      v-if="confirm.enable"
    />
    <gallery
      id="gallery-products"
      :images="galleryImages"
      :index="galleryIndex"
      @close="galleryIndex = null"
    />
    <Snackbar :toaster="toaster" />
  </div>
</template>

<script>
import { IDS } from "../Constants/IDS";
import { SUBSCRIBE_ALL_PRODUCTS_WITH_PRICES } from "../graphql/subscriptions/Products";
import Vue from "vue";
import ConfirmationDialog from "../components/Common/ConfirmationDialog";
import Snackbar from "../components/Common/Snackbar";
import updateRecord from "../mixins/updateRecord";
import SchemeForm from "../components/Products/SchemeForm";
import gallery from "../mixins/gallery";
import draggable from "vuedraggable";
import { CREATE_UPDATE_PRODUCT } from "../graphql/mutations/Products";
import UpdateProductPrice from "../components/Products/UpdateProductPrice";
import UpdateProductName from "../components/Products/UpdateProductName.vue";
export default {
  name: "Products",
  components: {
    UpdateProductPrice,
    UpdateProductName,
    SchemeForm,
    Snackbar,
    ConfirmationDialog,
    draggable,
  },
  mixins: [updateRecord, gallery],
  data() {
    return {
      loading: true,
      products: [],
      user: JSON.parse(localStorage.user),
      toaster: { enable: false },
      confirm: { enable: false },
      formDialog: false,
      productId: null,
      drag: true,
      search: "",
      packageColor: JSON.parse(localStorage.packageColor),
      updateProductPriceDialog: false,
      updateProductNameDialog: false,
    };
  },
  apollo: {
    $subscribe: {
      Products: {
        query: SUBSCRIBE_ALL_PRODUCTS_WITH_PRICES,
        result({ data }) {
          this.mapProductPrices(data.Products);
        },
      },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    isUserHasPermission() {
        const userType = this.user?.userType?.name?.toLowerCase()
        switch(userType) {
            case 'staff':
                return false;
            default:
                return true;
        }
    }
  },
  methods: {
    async updateSequence() {
        if (this.isUserHasPermission) {
            await this.$apollo.mutate({
                mutation: CREATE_UPDATE_PRODUCT,
                variables: {
                product: this.products.map(({ id, name }, index) => ({
                    id,
                    name,
                    sequence: index + 1,
                })),
                updateColumns: ["sequence"],
                },
            });
            this.toaster = {
                enable: true,
                color: "green",
                message: `Sequence of Products Updated Successfully`,
            };
        }
    },
    mapProductPrices(products) {
      products.forEach((p) => {
        p.ProductPrices.forEach((c) => {
          if (
            c.unitId === IDS.unitType.Box &&
            c.priceListId === IDS.userPriceList.DistributorSurat
          )
            p["DistributorBoxPrice"] = c.price;
          else if (
            c.unitId === IDS.unitType.Box &&
            c.priceListId === IDS.userPriceList.DealerSurat
          )
            p["SubDistributorBoxPrice"] = c.price;
          else if (
            c.unitId === IDS.unitType.Box &&
            c.priceListId === IDS.userPriceList.RetailerSurat
          )
            p["RetailerBoxPrice"] = c.price;
          else if (
            c.unitId === IDS.unitType.Piece &&
            c.priceListId === IDS.userPriceList.DistributorSurat
          )
            p["DistributorPiecePrice"] = c.price;
          else if (
            c.unitId === IDS.unitType.Piece &&
            c.priceListId === IDS.userPriceList.DealerSurat
          )
            p["SubDistributorPiecePrice"] = c.price;
          else if (
            c.unitId === IDS.unitType.Piece &&
            c.priceListId === IDS.userPriceList.RetailerSurat
          )
            p["RetailerPiecePrice"] = c.price;
        });
      });
      this.loading = false;
      this.products = products;
    },
    onSearch(val) {
      val = val?.toLowerCase() || "";
      this.products.forEach((p) =>
        Vue.set(p, "isHidden", p.displayName.toLowerCase().indexOf(val) === -1)
      );
    },
    openSchemeForm(row) {
      this.productId = row.id;
      this.formDialog = true;
    },
    openUpdateProductPrice(row) {
      this.productId = row.id;
      this.updateProductPriceDialog = true;
    },
    openUpdateProductName(row) {
      this.productId = row.id;
      this.updateProductNameDialog = true;
    },
    onUpdateStatus(row) {
      this.confirm = {
        enable: true,
        message: `Are you sure want to ${
          row.isEnable ? "enable" : "disable"
        } product ?`,
        row,
        method: this.updateStatusOfProduct,
        closeMethod: this.revertStatus,
      };
    },
    revertStatus() {
      this.confirm.row.isEnable = !this.confirm.row.isEnable; /* Revert value */
      this.confirm = { enable: false };
    },
    async updateStatusOfProduct() {
      let row = this.confirm.row;
      let obj = { isEnable: row.isEnable, updatedBy: this.user.id };
      await this.updateRecord(row.id, obj, "Products");
      this.confirm = { enable: false };
      this.toaster = {
        enable: true,
        color: "green",
        message: `Status of Product Updated Successfully`,
      };
    },
  },
};
</script>

<style scoped>
.product-card {
  max-height: calc(100vh - 188px);
  overflow-y: auto;
  overflow-x: hidden;
}
.product-card .row {
  margin: unset;
}
.pieces-label {
  color: darkgray;
  font-size: 14px;
  margin: 2px 0px;
}
img {
  cursor: pointer;
}
.drag-icon {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 50px;
  left: 12px;
}
.p-row:hover .drag-icon {
  visibility: visible;
}
.p-row {
  cursor: pointer;
}
</style>
