import gql from 'graphql-tag'

export const GET_USER_TYPES = gql`
    query {
        UserTypes: UserTypes(where:{assigned:{_eq:2}}){
            id
            name
            assigned
        }
    }`
