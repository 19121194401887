<template>
    <div>
  
      <v-dialog v-model="dialog" persistent width="800" scrollable>
        <v-card>
          <v-card-title class="text-h5 pl-6 font-weight-regular">Update Product Name & Weight
            <v-spacer/><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title><v-divider/>
          <v-progress-linear :active="$apollo.queries.Products.loading" :indeterminate="true"/>
          <v-card-text class="mt-4">
            <v-form ref="form">
              <v-row>
                <v-col cols="3">
                  <div class="raj-wafer-images text-center" v-if="product.image">
                    <!-- <img width="50" class="d-block mx-auto" alt="imges not found" :src="imgWpx(product.image, 200)"> -->
                    <!-- <v-chip label text-color="white" class="mt-3" x-small v-if="product.packageType"
                            :style="`background-color: ${packageColor[product.packageType]}`">
                      {{ product.packageType.toUpperCase() }}
                    </v-chip> -->
                  </div>
                  <!-- <div style="position: relative;top: calc(50% - 13px);text-align: -webkit-center;" v-else><v-icon>image</v-icon></div> -->
                </v-col>
                <v-col cols="9">
                  <div>
                    <!-- <h6 class="text-subtitle-1 font-weight-bold"
                        :style="{'color': product.colorCode?product.colorCode: '#3172d0'}">
                      {{product.displayName}} 
                    </h6> -->
                    <!-- <p class="text-caption font-italic mb-0">{{ product.noOfPieceInBox }} Pieces in {{product.containerType}}</p> -->
                  </div>
                </v-col>
                <v-col cols="12">
  <v-row class="justify-space-between">
    <v-col cols="4">
      <div class="text-h6">Product Name</div>
    </v-col>
    <v-col cols="8">
      <v-text-field v-model="product.displayName" dense outlined prepend-inner-icon hide-details type="text"></v-text-field>
    </v-col>
    <v-col cols="4">
      <div class="text-h6">Product weight (in gm)</div>
    </v-col>
    <v-col cols="8">
      <v-text-field v-model="product.weight" dense outlined prepend-inner-icon hide-details type="text"></v-text-field>
    </v-col>
  </v-row>
</v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize elevation-0 mr-2" @click="closeDialog">Cancel</v-btn>
            <v-btn class="primary px-3 text-capitalize" :loading="formLoading" dark @click="handleSubmit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <Snackbar :toaster="toaster"/>
    </div>
  </template>
  
  <script>
  import {GET_PRODUCT_WITH_PRICES} from "../../graphql/queries/Products";
  import Snackbar from "../Common/Snackbar";
  import {IDS} from "../../Constants/IDS";
  import { CREATE_UPDATE_PRODUCT_NAME_AND_WEIGHT } from "../../graphql/mutations/Products";
  import {roundOffValue} from "../../util/helpers";
  let prices = {distributor: 0, dealer: 0, retailer: 0};            //Box prices
  export default {
    name: "UpdateProductName",
    components: {Snackbar},
    props: ['dialog', 'productId'],
    apollo: {
      Products: {
        query: GET_PRODUCT_WITH_PRICES,
        fetchPolicy: 'network-only',
        skip () {return !this.productId},
        variables(){ return{ id: this.productId } },
        result ({data}) { this.afterFetchProduct(data.Products[0]) },
      },
    },
    data(){
      return{
        product: {},
        prices: {...prices},
        additionalPrice: 0,
        formLoading: false,
        toaster: {enable: false},
        packageColor: JSON.parse(localStorage.packageColor),
      }
    },
    computed:{
      distributorPiecePrice(){
        return roundOffValue(this.prices.distributor/this.product.noOfPieceInBox);
      },
      dealerPiecePrice(){
        return roundOffValue(this.prices.dealer/this.product.noOfPieceInBox);
      },
      retailerPiecePrice(){
        return roundOffValue(this.prices.retailer/this.product.noOfPieceInBox);
      },
      additionalPiecePrice(){
        return roundOffValue(this.additionalPrice/this.product.noOfPieceInBox);
      }
    },
    methods:{
      afterFetchProduct(product){
        this.product = product;
        product.ProductPrices.forEach(p => {
          if(p.priceListId === IDS.userPriceList.DistributorSurat && p.unitId === IDS.unitType.Box){
            this.prices.distributor = p.price;
            this.additionalPrice = p.additionalPrice || 0;
          }else if(p.priceListId === IDS.userPriceList.DealerSurat && p.unitId === IDS.unitType.Box){
            this.prices.dealer = p.price;
          }else if(p.priceListId === IDS.userPriceList.RetailerSurat && p.unitId === IDS.unitType.Box){
            this.prices.retailer = p.price;
          }
        })
      },
      handleSubmit() {
      if (this.$refs.form.validate()) {
        this.updateProduct();
      }
    },
    async updateProduct() {
      this.formLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: CREATE_UPDATE_PRODUCT_NAME_AND_WEIGHT,
          variables: {
            productId: this.product.id,
            displayName: this.product.displayName,
            weight: this.product.weight,
          },
        });
        this.formLoading = false;
        this.toaster = { enable: true, color: "green", message: `Product Name and weight updated Successfully` };
        this.closeDialog();
      } catch (error) {
        console.error("Error updating product:", error);
        this.formLoading = false;
        this.toaster = { enable: true, color: "red", message: "Error updating product" };
      }
    },
      closeDialog(){
        this.$refs.form.resetValidation();
        this.prices = {...prices};
        this.product = {};
        this.additionalPrice = 0;
        this.$emit('update:dialog', false);
        this.$emit('update:productId', null);
      }
    }
  }
  </script>
  
  <style scoped>
  .header6{
    font-size: 1.1rem!important;
    font-weight: 500;
    letter-spacing: .0125em!important;
    color: rgba(0,0,0,.6);
  }
  </style>

